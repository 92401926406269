<template>
  <div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="firstName">First Name</label>
          <p id="firstName">{{ userDetail.firstName }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="lastName">Last Name</label>
          <p id="lastName">{{ userDetail.lastName }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Email</label>
          <p id="email">{{ userDetail.email }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="phoneNumber">Phone Number</label>
          <p id="phoneNumber">{{ userDetail.phoneNumber }}</p>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: "ProfileDetail",
  data: () => ({
    userDetail: {},
  }),
  methods: {},
  async created() {
    this.userDetail = this.$store.state.AppActiveUser;

    this.userDetail.firstName = (this.userDetail.firstName) ? this.userDetail.firstName : this.userDetail.fullName.split(" ")[0];
    this.userDetail.lastName = (this.userDetail.lastName) ? this.userDetail.lastName : this.userDetail.fullName.split(" ")[1];
  },
};
</script>

<style>
p {
  font-size: 15px;
  font-weight: 500;
}
</style>
