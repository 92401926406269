<template>
  <vs-row id="profile-page">
    <vx-card class="accordin-card mt-5 w-full profile-view">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item open ref="profileRef">
            <div slot="header">
              <div class="flex items-center">
                <S3ImageGenerator
                  v-if="(dataImg && userImage) || userDetail.profileImage"
                  :is-base64="dataImg ? userImage : false"
                  :document="dataImg ? (userImage ? dataImg : userDetail.profileImage): userDetail.profileImage"
                  :key="dataImg ? (userImage ? dataImg : userDetail.profileImage): userDetail.profileImage"
                  :customClass="'profile-image-circle-60 rounded-full shadow-md cursor-pointer block profile-img'"
                />
                <h3 v-else class="font-medium alpha-avatar view-pro  cursor-pointer rounded-full shadow-md">{{activeUserInfo && activeUserInfo.fullName ? activeUserInfo.fullName.split(' ').map((item) => item.charAt(0)).join('').toUpperCase() : ""}}</h3>
                <div class="ml-4 md:ml-6">
                  <h4 class="mb-0 font-normal text-base md:text-xl lg:text-2xl">{{ userDetail.fullName }}</h4>
                  <p class="text-base font-normal">at {{ userDetail.companyName }}</p>
                </div>
              </div>
            </div>
            <div class="wrapper mt-1 gap-4">
              <h4 class="text-xl mb-2 font-normal">Personal information</h4>
              <hr class="shadow-line-hr mb-6 md:mb-8 lg:mb-10 mt-2" />
              <vs-row>
                <div class="input-grp mb-6 md:mb-8">
                  <vs-input
                    v-model="userDetail.fullName"
                    v-validate="'required'"
                    class="w-full"
                    data-vv-as="full name"
                    data-vv-validate-on="blur"
                    label="Full Name"
                    name="fullName"
                    size="large"
                  />
                  <span class="text-danger text-sm">
                    {{ errors.first("fullName") }}
                  </span>
                </div>
                <div class="flex flex-wrap">
                  <div class="input-grp">
                    <label class="w-full my-0">Email Address</label>
                    <vs-input
                      v-model="userDetail.email"
                      v-validate="'required|email'"
                      class="w-full"
                      data-vv-as="email"
                      data-vv-validate-on="blur"
                      name="email"
                      size="large"
                    />
                    <span class="text-danger text-sm">
                    {{ errors.first("email") }}
                  </span>
                  </div>
                  <div class="input-grp">
                    <label class="w-full my-0">Phone number</label>
                    <the-mask
                      :class="
                        userDetail.phoneNumber
                          ? 'vs-inputx vs-input--input large'
                          : 'vs-inputx vs-input--input large'
                      "
                      :mask="[checkPhoneNumber]"
                      v-model="userDetail.phoneNumber"
                      masked
                      name="phoneNumber"
                      id="phoneNumber"
                      ref="phoneNumber"
                      v-validate="'phoneFormat'"
                      placeholder=""
                    />
                  <span class="text-danger text-sm">
                    {{ errors.first("phoneNumber") }}
                  </span>
                  </div>
                </div>
              </vs-row>
              <h4 class="text-lg mt-10">Profile picture</h4>
              <hr class="shadow-line-hr mb-6 mt-4" />
              <vs-row class="mb-6 md:mb-8">
                <vs-col vs-w="2.5" class="">
                  <div v-if="(userImage && userDetail.profileImage) || dataImg" class="flex flex-wrap justify-between p-1">
                    <S3ImageGenerator
                      :is-base64="dataImg ? true : false"
                      :document="dataImg ? dataImg : userDetail.profileImage"
                      :key="dataImg ? dataImg : userDetail.profileImage"
                      :customClass="'profile-page-150 rounded-full '"
                    />
                    <feather-icon
                      icon="XIcon"
                      class="ml-1 flex items-start"
                      @click="clearImage"
                    />
                  </div>
                  <input
                    ref="updateImgInput"
                    v-validate="{ size: maxFileSize }"
                    accept="image/*"
                    class="hidden"
                    data-vv-as="image"
                    name="image_field"
                    type="file"
                    @change="updateCurrImg"
                  />
                  <div v-if="!(userImage && userDetail.profileImage) && !dataImg">
                    <vs-button
                      v-round
                      size="medium"
                      class="block only-border-btn"
                      @click="$refs.updateImgInput.click()"
                    ><template>
                      <upload-icon
                        size="1.5x"
                        class="custom-class"
                      ></upload-icon></template
                    >Upload Image</vs-button
                    >

                  </div>
                  <span class="text-danger text-sm" v-show="errors.has('image_field')">{{ errors.first("image_field") }}</span>
                </vs-col>
              </vs-row>

              <h4 class="text-lg mt-10">Password</h4>
              <hr class="shadow-line-hr mb-6 mt-4" />
              <vs-row vs-w="4">
                <div class="input-grp mb-8">
                  <label class="w-full my-0 text-base">Current Password</label>
                  <vs-input
                    id="currentPassword"
                    v-model="user.currentPassword"
                    :icon="passwordShowIcon"
                    :type="passwordFieldType"
                    class="passwordInput"
                    data-vv-validate-on="blur"
                    icon-after="true"
                    icon-no-border
                    icon-pack="feather"

                    name="currentPassword"
                    @click.native="
                      switchPasswordVisibility($event, 'currentPassword')
                    "
                  />
                  <span class="text-danger text-sm">{{
                      errors.first("currentPassword")
                    }}</span>
                </div>
                <div class="input-grp mb-8">
                  <label class="w-full my-0 text-base">New Password</label>
                  <vs-input
                    id="newPassword"
                    v-model="user.newPassword"
                    v-validate="'passwordFormat'"
                    :icon="newPasswordShowIcon"
                    :type="newPasswordFieldType"
                    class="passwordInput"
                    data-vv-validate-on="blur"
                    icon-after="true"
                    icon-no-border
                    icon-pack="feather"

                    name="newPassword"
                    @click.native="
                      switchPasswordVisibility($event, 'newPassword')
                    "
                  />
                  <span class="text-danger text-sm">{{
                      errors.first("newPassword")
                    }}</span>
                </div>
                <div class="input-grp mb-8">
                  <label class="w-full my-0 text-base">Confirm password</label>
                  <vs-input
                    id="confirmNewPassword"
                    v-model="user.confirmNewPassword"
                    :icon="confirmPasswordShowIcon"
                    :type="confirmPasswordFieldType"
                    class="passwordInput"
                    data-vv-validate-on="blur"
                    icon-after="true"
                    icon-no-border
                    icon-pack="feather"

                    name="confirmNewPassword"
                    @click.native="
                      switchPasswordVisibility($event, 'confirmPassword')
                    "
                  />
                  <span class="text-danger text-sm">{{
                      errors.first("confirmNewPassword")
                    }}</span>
                </div>
              </vs-row>
            </div>
            <div class="actions text-right mt-4">
      <vs-button
        v-round
        size="large"
        class="btn btn-primary"
        @click="updateAdminProfileData"
        :disabled="errors.items.length > 0"
        >Save</vs-button
      >
      <span class="mid-blue edit-profile ml-8"
      @click="$router.push({name:'partner-dashboard'})"
        >Cancel</span
      >
    </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>

    </vx-card>

    <pop-up :errorsList="errors" form-name="NotificationForm"></pop-up>
    <leave-popup
      :popup-active="popupActive"
      @handleClick="popActions"
    ></leave-popup>
    <!-------- ALL POPUPS ------------>

  </vs-row>
</template>

<script>
import ProfileDetail from "@/views/components/profile/ProfileDetail";
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import RadioBox from "@/views/components/radiobox";
import MultiSelectExtended from "@/views/components/MultiSelectExtended";
import _ from "lodash";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import { UploadIcon } from "vue-feather-icons";
import { TheMask } from "vue-the-mask";
import S3ImageGenerator from "../components/S3ImageGenerator";
import { leavePopUpMixin } from "../../mixins/leave-pop-up";
import { EventBus } from "../../../event-bus";

const dictionary = {
  en: {
    attributes: {
      currentPassword: "current password",
      newPassword: "new password",
      confirmNewPassword: "confirm new password",
    },
  },
};
Validator.localize(dictionary);
export default {
  mixins: [leavePopUpMixin],
  components: {
    ProfileDetail,
    MultiSelectExtended,
    RadioBox,
    PopUp,
    LeavePopup,
    UploadIcon,
    TheMask,
    S3ImageGenerator
  },
  data() {
    return {
      doLogout: false,
      goingToObject: null,
      nextObj: "", // determines the next route,
      isSaved: false,
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      userDetail: {},
      previousEmail: "",
      newImg: null,
      dataImg: null,
      passwordFieldType: "password",
      passwordShowIcon: "icon icon-eye-off",
      newPasswordFieldType: "password",
      newPasswordShowIcon: "icon icon-eye-off",
      confirmPasswordFieldType: "password",
      confirmPasswordShowIcon: "icon icon-eye-off",
      iconWrap: {
        marginTop: "-35px",
      },
      user: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      formData: {
        planName: "",
      },
      userImage: true,
      redirect: true,
      maxFileSize: "",
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },

    userName(){
      var names = this.userDetail.fullName.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    checkPhoneNumber(){
      let mask = '#### ### ###';
      if(this.userDetail.phoneNumber && this.userDetail.phoneNumber.length > 0){
        let numLength = this.userDetail.phoneNumber.length;
        let value1 = '';
        let value2 = '';
        if(numLength >= 2){
          value1 = this.userDetail.phoneNumber.substring(0,2);
          if(value1 === '13'){
            value2 = this.userDetail.phoneNumber.substring(0,4);
          }
        }
        if(value1 === '02' || value1 === '03' || value1 === '07' || value1 === '08'){
          mask = "## #### ####"
        }else{
          mask = "#### ### ###"
        }

        if(value1 === '13'){
          if(parseInt(value2) >= 1300 || value2 === '130' || value2 === '13 0'){
            mask = "#### ### ###"
          }else{
            mask = "## ## ##"
          }
        }
      }
      return mask;
    }
  },
  methods: {
    ...mapActions("user", ["updateAdminProfile", "updateAdminImage"]),
    ...mapActions("admin", ["changePassword", "checkLogin"]),
    switchPasswordVisibility(event, type) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-after icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-after icon-no-border"
      ) {
        if (type === "newPassword") {
          this.switchNewPasswordVisibility();
        } else if (type === "confirmPassword") {
          this.switchConfirmPasswordVisibility();
        } else {
          this.passwordFieldType =
            this.passwordFieldType === "password" ? "text" : "password";
          this.passwordShowIcon =
            this.passwordShowIcon === "icon icon-eye"
              ? "icon icon-eye-off"
              : "icon icon-eye";
        }
      }
      return true;
    },

    switchNewPasswordVisibility() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.newPasswordShowIcon =
        this.newPasswordShowIcon === "icon icon-eye"
          ? "icon icon-eye-off"
          : "icon icon-eye";
    },

    switchConfirmPasswordVisibility() {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === "password" ? "text" : "password";
      this.confirmPasswordShowIcon =
        this.confirmPasswordShowIcon === "icon icon-eye"
          ? "icon icon-eye-off"
          : "icon icon-eye";
    },
    updateAdminProfileData() {
      this.$vs.loading();
      this.redirect = true;
      if (this.dataImg) {
        let data = new FormData();

        data.append("_id", this.userDetail._id);
        data.append("profileImage", this.newImg);

        let obj = {
          obj: data,
          config: {
            header: {
              "Content-Type": "application/json",
            },
          },
        };

        this.updateAdminImage(obj)
          .then((response) => {
            this.uploadedImage = response.data.data.file;
            this.updateUserDetail();
            if (this.user.currentPassword && this.user.newPassword && this.user.confirmNewPassword) {
              this.changeAdminPassword();
            } else if (this.user.currentPassword || this.user.newPassword || this.user.confirmNewPassword) {
              if (!this.user.currentPassword || !this.user.newPassword || !this.user.confirmNewPassword) {
                this.$vs.notify({
                  title: "Message",
                  text: "Password change was unsuccessful",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                });
                this.redirect = false;
              }
            }
            this.$vs.loading.close();
            if (this.redirect) {
              this.$router.push({ name: 'partner-dashboard' });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: error.response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } else {
        this.updateUserDetail();
        if (this.user.currentPassword && this.user.newPassword && this.user.confirmNewPassword) {
          this.changeAdminPassword();
        } else if (this.user.currentPassword || this.user.newPassword || this.user.confirmNewPassword) {
          if (!this.user.currentPassword || !this.user.newPassword || !this.user.confirmNewPassword) {
            this.$vs.notify({
              title: "Message",
              text: "Password change was unsuccessful",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            this.redirect = false;
          }
        }
        this.$vs.loading.close();
        if (this.redirect) {
          this.$router.push({ name: 'partner-dashboard' });
        }
      }
    },
    changeAdminPassword() {
      const payload = {
        userDetails: this.user,
      };
      if (this.user.newPassword == this.user.confirmNewPassword) {

        this.changePassword(payload)
          .then((response) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Message",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });

            this.showPassswordModal = false;
          })
          .catch((error) => {
            this.isSaved = true;
            this.redirect = false;
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } else {
        this.isSaved = true;
        this.redirect = false;
        this.errors.add({
          field: "confirmNewPassword",
          msg: "The confirm new password confirmation does not match",
        });
      }
    },
    updateUserDetail() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.userDetail._id = user._id;
      let data = {
        _id: user._id,
        fullName: this.userDetail.fullName,
        profileImage: this.uploadedImage,
        email: this.userDetail.email,
        phoneNumber: this.userDetail.phoneNumber,
      };
      user.fullName = this.userDetail.fullName;
      user.profileImage= this.uploadedImage;
      user.email= this.userDetail.email;
      user.phoneNumber= this.userDetail.phoneNumber;

      this.updateAdminProfile(data)
        .then((res) => {
          this.$vs.loading.close();
          let responseData = res.data.data;
          responseData.token = user.token;
          localStorage.setItem("user", JSON.stringify(user));

          this.$vs.notify({
            title: "Details Updated",
            text: res.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });

          this.$emit("updateProfile");
          this.isSaved = true;

        })
        .catch((error) => {
          ;
          this.$vs.notify({
            title: "Error",
            text: error.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    clearImage() {
      this.dataImg = null;
      this.userImage = false;
      this.uploadedImage = '';
      this.userDetail.profileImage = "";
      this.$refs.updateImgInput.value = "";
      if (this.errors.has(`image_field`)) {
        this.errors.remove(`image_field`);
      }
    },
    updateCurrImg(input) {
      if (
        input.target.files &&
        input.target.files[0] &&
        /\.(jpe?g|png|gif)$/i.test(input.target.files[0].name)
      ) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
        this.newImg = input.target.files[0];
        this.userImage = true;
        if ('maxHeight' in this.$refs.profileRef)
          this.$refs.profileRef.maxHeight = "none !important";
      }
    },
    async checkAdminLogin() {
      await this.checkLogin().then((res) => {
        this.maxFileSize = res.data.data.maxLogoSize;
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty && !this.isSaved) {
      this.nextObj = next;
      this.goingToObject = to;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    const id = user ? user._id : "";
    this.userDetail = { ...this.$store.state.AppActiveUser };
    this.previousEmail = this.userDetail.email;
    this.$validator.extend("passwordFormat", {
      getMessage: () =>
        "Password must contain at least one uppercase, lowercase, digit, one special character and 10 characters long",
      validate: (value) =>
        value.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{10,}$/
        ) !== null,
    });
    this.$validator.extend("phoneFormat", {
      getMessage: () =>
        "The phone number format is invalid.",
      validate: (value) =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/
        ) !== null
    });
    this.checkAdminLogin();
    EventBus.$on("logoutSuccess", payload => {
      this.doLogout = payload.logout
    });

  },
  watch: {
    isFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    isSaved(latest, previous) {
      if (!latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    "user.confirmNewPassword"(latest, previous) {
      if (this.errors.has(`confirmNewPassword`)) {
        this.errors.remove(`confirmNewPassword`);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
